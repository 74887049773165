import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createUserAsync, selectAllUsers, addUser, updateUserAsync, updateUser } from './userSlice';
import { getAuthToken } from '../authentication/authenticationSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import { addNotification, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

const wait = (seconds) => 
    new Promise(
        resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
);

export function UserPopup ({
    isOpen,
    onClose,
    selectedID
  }) {

    const dispatch = useDispatch();

    const initialValues = {
        id: null,
        name: '',
        email: '',
        password: '',
        role: 'user',
        isActive: true,
        isSubmitted: false
    };

    const [values, setValues] = React.useState(initialValues)
    const users = useSelector(selectAllUsers);
    const user = users.find(u => u.id === selectedID)

    if (user && user.id !== values.id) {
        setValues({
            id:             user.id,
            name:           user.name,
            email:          user.email,
            role:           user.role,
            isActive:       user.isActive,
            isSubmitted:    false
        })
    }
    else if (!user && values.id !== null) {
        setValues(initialValues)
    }

    const isValidEmail = (email) => /(.+)@(.+){1,}\.(.+){1,}/.test(email)

    const handleChange = field => event => {
      const { value } = event.target
      setValues(vals => ({ ...vals, [field]: value }))
    }

    
    const handleClose = () => {
        setValues(initialValues)
        onClose()
    }
    
      
    const idToken = useSelector(getAuthToken);

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setValues(vals => ({ ...vals, isSubmitted: true }))
        const { id, name, email, role, password, isActive } = values
        if (String(name).length === 0) return
        if (String(email).length === 0 || !isValidEmail(email)) return

        // New user
        if(user === undefined ) {
          dispatch( createUserAsync({idToken, name, email, role, password} ) ).unwrap().then((result) => {
              // handle result here
              dispatch( addUser({ id: result.id, name, email, role, password, isActive: 1 } ) );

              let timestamp = Date.now();
              let msg = new uiMsg("New user created.", "INFO", timestamp);
              dispatch( addNotification(JSON.stringify(msg) ) );
      
              wait(3).then(function() {
                  dispatch( dismissNotification(timestamp) );
              })

              onClose();
          })
          .catch((error) => {  
              // handle error here
              let timestamp = Date.now();
              let msg = new uiMsg(error.message === "401" ? "Unauthorised to add users." : error.message, "WARNING", timestamp);
              dispatch( addNotification(JSON.stringify(msg) ) );
      
              wait(3).then(function() {
                  dispatch( dismissNotification(timestamp) );
              })
          });
      }
      // Update user
      else {
        dispatch( updateUserAsync({idToken, id, name, email, role, isActive} ) ).unwrap().then((result) => {
            // handle result here
            dispatch( updateUser({ id, name, email, role, isActive } ) );

            let timestamp = Date.now();
            let msg = new uiMsg("User updated.", "INFO", timestamp);
            dispatch( addNotification(JSON.stringify(msg) ) );
    
            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })

            onClose();
        })
        .catch((error) => {  
            // handle error here
            let timestamp = Date.now();
            let msg = new uiMsg(error.message === "401" ? "Unauthorised to update users." : error.message, "WARNING", timestamp);
            dispatch( addNotification(JSON.stringify(msg) ) );
    
            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })
        });

      }
    };


    const defaultTextFieldProps = {
        fullWidth: true,
        variant: 'outlined'
      }
    
      const fields = [
        {
          ...defaultTextFieldProps,
          autoFocus: true,
          id: 'name',
          label: 'Full Name',
          type: 'text',
          required: true,
          value: values.name,
          onChange: handleChange('name'),
          error: values.isSubmitted && String(values.name).length === 0
        },
        {
          ...defaultTextFieldProps,
          id: 'email',
          label: 'Email Address (for login)',
          type: 'email',
          required: true,
          value: values.email,
          onChange: handleChange('email'),
          error: values.isSubmitted && (String(values.email).length === 0 || !isValidEmail(values.email))
        }
      ]
    
      if(!user) {
        fields.push(
          {
            ...defaultTextFieldProps,
            id: 'password',
            label: 'Password',
            type: 'password',
            required: true,
            value: values.password,
            onChange: handleChange('password'),
            error: values.isSubmitted && String(values.password).length === 0
          }
        );
      }


      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      return (
        <Modal
          open={isOpen}
          onClose={onClose}
          title={user !== undefined ? "Update User" : "New User"}
        >
            <Box sx={style} component="form" onSubmit={handleSubmit}>

                <h2 style={{marginBottom: "0px"}}>{user !== undefined ? "Update User" : "Create New User"}</h2>
                <DialogContentText style={{marginBottom: "15px"}}>
                    Fill out the fields to add a new user or admin.
                </DialogContentText>

                <Grid container spacing={2}>
                    {fields.map(({ id, ...props }) => (
                    <Grid key={id} item xs={12}>
                        <TextField id={id} {...props} />
                    </Grid>
                    ))}
                    <Grid item xs={12}>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend' required>Role</FormLabel>
                            <RadioGroup row aria-label='role' name='role' value={values.role} onChange={handleChange('role')}>
                            <FormControlLabel
                                value='user'
                                control={<Radio color='primary' />}
                                label='User'
                            />
                            <FormControlLabel
                                value='admin'
                                control={<Radio color='primary' />}
                                label='Admin'
                            />

                            </RadioGroup>
                        </FormControl>
                    </Grid>


                    <Box sx={{ flexGrow: 1 }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                        <Grid container spacing={2}>
                            <Grid size={6}>
                                <Button variant="outlined" onClick={ handleSubmit }>{user !== undefined ? "Update" : "Create"}</Button>
                            </Grid>
                            <Grid size={6}>
                                <Button variant="outlined" onClick={ handleClose }>Close</Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
            </Box>

        </Modal>
      )
    }