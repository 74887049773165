import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDismissedNotifications
} from './notificationSlice';

export function DismissedNotifications() {

  const [visibility, setVisibility] = useState(false);

  function showHideHistory() {
    setVisibility(!visibility);
  }

  const notifications = useSelector(selectDismissedNotifications, {
    devModeChecks: { stabilityCheck: "never" },
  });
  
  const sortedNotifications = notifications.sort((a, b) => {
    return b.dateTimeOf - a.dateTimeOf;
  });

  const listItems = sortedNotifications.map(msg => {

    return <li key={msg.dateTimeOf}  style={{ listStyleType: "none", margin: "0px", backgroundColor: msg.type === "INFO" ? "lightgreen" : (msg.type === "WARNING" ? "lemonchiffon" : "#FFCCCB" ), padding: "5px", marginBottom: "5px", border: "1px solid grey" }} >
      { (new Date(msg.dateTimeOf) ).toLocaleDateString("en-au") } { (new Date(msg.dateTimeOf)).toLocaleTimeString() }
      <div className="content" dangerouslySetInnerHTML={{ __html: msg.message }}></div>
    </li>;
  });

  return (
    <div>
        <div style={{position: "absolute", right: "0", transform: (visibility === true ? "rotate(180deg)" : "rotate(0deg)"), fontWeight: "bolder", cursor: "pointer", display: (listItems.length > 0 ? "block" : "none" ) }} onClick={showHideHistory}>^</div>
        {/* { listItems.length > 0 && <p>Dismissed:</p> } */}
        <ul style={{ paddingLeft: "0px", display: "inline-block", display: (visibility === true ? "block" : "none" ), position: "relative", top: "20px", maxHeight: "50vh", overflowY: "auto" }}>{listItems}</ul>
    </div>
  );
}
