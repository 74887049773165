import React, { useState, Component, Fragment } from 'react';
import ReactDOM from "react-dom/client";

/** We are importing our index.php my app Variable */
import Header from './Header';
import Footer from './Footer';

import { Provider } from 'react-redux'
import { store } from './stores/store';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { Main } from './features/main/Main';

import { BrowserRouter as Router } from 'react-router-dom';

/* globals __webpack_public_path__ */
__webpack_public_path__ = `${window.STATIC_URL}/app/assets/bundle/`;

class Myapp extends Component {
    render() {

        return (
            <Router>
                <Fragment>
                    <Header style={{ position: "relative" }}/>

                    <Main />

                    <Footer />
                    
                </Fragment>
            </Router>
        )
    }
}

let persistor = persistStore(store);

const root = ReactDOM.createRoot( document.getElementById("app") );
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Myapp/>
        </PersistGate>
        
    </Provider>
);