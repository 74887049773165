import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getToDos, addToDo as sendAddToDo, updateToDo as sendUpdateToDo, deleteToDo as sendDeleteToDo, toggleToDoCompletion as toggleToDoCompletionRq } from './toDoAPI'

const initialState = {
  list: []
}
export const getToDosAsync = createAsyncThunk(
    'todo/getToDos',
    async (idToken) => {

        const response = await getToDos(idToken);
  
        if(Array.isArray(response)) {
          return response;  
        }
  
        // The value we return becomes the `fulfilled` action payload
        return [response];
    }
);

export const createToDoAsync = createAsyncThunk(
    'todo/createToDo',
    async (newToDoRq ) => {

        const response = await sendAddToDo(newToDoRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const updateToDoAsync = createAsyncThunk(
    'todo/updateToDo',
    async (updateToDoRq ) => {

        const response = await sendUpdateToDo(updateToDoRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const deleteToDoAsync = createAsyncThunk(
    'todo/deleteToDo',
    async (deleteToDoRq ) => {

        const response = await sendDeleteToDo(deleteToDoRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const toggleToDoCompletionAsync = createAsyncThunk(
    'todo/toggleToDoCompletion',
    async ( toggleToDoRq ) => {

        const response = await toggleToDoCompletionRq( toggleToDoRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);


export const toDoSlice = createSlice({
  name: 'toDo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addToDo: (state, action) => {

      return {
        ...state,
        list: [...state.list, action.payload]
      }
    },

    deleteToDo: (state, action) => {

      var toDoId = action.payload;

      return {
        ...state,
        list: [...state.list.filter(td => td.id !== toDoId)]
      }
    },

    setToDos: (state, action) => {

      return {
        ...state,
        list: action.payload
      }
    },

    updateToDo: (state, action) => {

      var toDo = action.payload;

      return {
        ...state,
        list: state.list.map(td => {
          if (td.id === toDo.id) {
            return toDo
          }
          return { ...td }
        })
      }
    },

    removeCategoryFromToDo: (state, action) => {
      var {toDoID, categoryID} = action.payload;

      return {
        ...state,
        list: state.list.map(td => {
          if (td.id === toDoID) {
            toDo.categories = toDo.categories.map(cat => {
              if (cat.id !== categoryID) {
                return cat
              }   
            })
            return toDo
          }

          return { ...td }
        })
      }
    },


    toggleToDoCompletion: (state, action) => {
      const toDoId = action.payload.toDoId
      const isCompleted = action.payload.isCompleted === true ? 1 : 0

      return {
        ...state,
        list: state.list.map(td => {
          if (td.id === toDoId) {
            return { ...td, isCompleted }
          }
          return { ...td }
        })
      }
    },
    clearState: () => {
      return initialState
    }
  },
});

export const { addToDo, clearState, deleteToDo, setToDos, toggleToDoCompletion, updateToDo, removeCategoryFromToDo } = toDoSlice.actions;

export const selectAllToDos = (state) => state.todo.list;

export default toDoSlice.reducer;
