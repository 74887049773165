import { authorisedRequest } from '../lib/httpRequest'
import { API_ENDPOINTS } from '../lib/api-endpoints'

/*
 * Users API
 */
export function getUsers (idToken) {
  const endpoint = API_ENDPOINTS.users
    .replace(/:USER_ID/, '')
    .replace(/\/$/, '')

  return authorisedRequest(idToken, { method: 'GET', endpoint })
    .then(response => {
      return [...response.users]
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function addUser ({idToken, name, email, role, password} ) {
  const endpoint = API_ENDPOINTS.users.replace(/:USER_ID/, '').replace(/\/$/, '')

  return authorisedRequest(idToken,
    {
      method: 'POST',
      endpoint,
      body: {
        name,
        email,
        role,
        password,
        isActive: true
      }
    })
    .then(response => {
      const { user_id: user_id } = response
      return { id: parseInt(user_id, 10) }
    })
    .catch(error => {
      // HTTP conflict, email address exists
      if (error.response && error.response.status === 409) {
        throw new Error('Email address already exists')
      } 
      else {
        throw new Error(error.message || 'There was a problem processing the request')
      }
    })
}

export function deleteUser ({ idToken, userId }) {
  const endpoint = API_ENDPOINTS.users.replace(/:USER_ID/, userId)

  return authorisedRequest(idToken, { method: 'DELETE', endpoint })
    .then(response => {
      const { deleted } = response
      return deleted
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function toggleUserStatus ({idToken, userId, isActive }) {
  const endpoint = API_ENDPOINTS.userStatus.replace(/:USER_ID/, userId)

  return authorisedRequest(idToken, { method: 'PUT', endpoint, body: { isActive } })
    .then(response => {
      const { updated } = response
      return updated
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export async function updateUser ({idToken, id, name, email, role, password, isActive}) {
  const endpoint = API_ENDPOINTS.users
    .replace(/:USER_ID/, id)
    .replace(/\/$/, '')

  isActive = isActive === 1;

  try {
    var response = await authorisedRequest(idToken, { method: 'PUT', endpoint, body: {name, email, role, password, isActive} })

    return response.updated === true
  }
  catch(error) {
    throw new Error(error.message || 'There was a problem processing the request')
  }
}


export async function changeOthersPassword ( { idToken, userId, password } ) {
  const endpoint = API_ENDPOINTS.changePassword + '/others' 
  return authorisedRequest(idToken, { method: 'PUT', endpoint, body: { userId, password } })
    .then(response => {
      const { updated } = response
      return updated
    })
    .catch(error => {
      // HTTP conflict, current password is not correct
      if (error.response && error.response.status === 409) {
        throw new Error('Incorrect current password')
      } 
      else {
        throw new Error(error.message || 'There was a problem processing the request')
      }
    })
}