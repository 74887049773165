import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrentNotifications
} from './notificationSlice';

export function CurrentNotifications() {

  const notifications = useSelector(selectCurrentNotifications, {
    devModeChecks: { stabilityCheck: "never" },
  });

  const sortedNotifications = notifications.sort((a, b) => {
    return b.dateTimeOf - a.dateTimeOf;
  });

  const listItems = sortedNotifications.map(msg => {

    return <li key={msg.dateTimeOf} style={{ listStyleType: "none", margin: "0px", backgroundColor: msg.type === "INFO" ? "lightgreen" : (msg.type === "WARNING" ? "lemonchiffon" : "#FFCCCB" ), padding: "5px", marginBottom: "5px", border: "1px solid grey" }} >
      { (new Date(msg.dateTimeOf) ).toLocaleDateString("en-au") } { (new Date(msg.dateTimeOf)).toLocaleTimeString() }
      <div className="content" dangerouslySetInnerHTML={{ __html: msg.message }}></div>
    </li>;
  });
  
  return (
    <div>
        {/* { listItems.length > 0 && <p>Current:</p> } */}
        <ul style={{paddingLeft: "0px"}}>{listItems}</ul>
    </div>
  );
}
