import { authorisedRequest } from '../lib/httpRequest'
import { API_ENDPOINTS } from '../lib/api-endpoints'

/*
 * ToDo API
 */
export function getToDos (idToken) {
  const endpoint = API_ENDPOINTS.toDos
    .replace(/:TODO_ID/, '')
    .replace(/\/$/, '')

  return authorisedRequest(idToken, { method: 'GET', endpoint })
    .then(response => {
      if(Array.isArray(response.toDos)) {
        return [...response.toDos];  
      }
      return [response.toDos];
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function addToDo ({idToken, title, details, isCompleted} ) {
  const endpoint = API_ENDPOINTS.toDos.replace(/:TODO_ID/, '').replace(/\/$/, '')

  return authorisedRequest(idToken, {
      method: 'POST',
      endpoint,
      body: {
        title,
        details,
        isCompleted
      }
    })
    .then(response => {
      const { todo_id: todo_id } = response
      return { id: parseInt(todo_id, 10) }
    })
    .catch(error => {
      // HTTP conflict, email address exists
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export async function deleteToDo ({ idToken, toDoId }) {
  const endpoint = API_ENDPOINTS.toDos.replace(/:TODO_ID/, toDoId)

  console.log(">> delete Todo endpoint: " + endpoint);

  return authorisedRequest(idToken, { method: 'DELETE', endpoint })
    .then(response => {
      const { deleted } = response
      return deleted
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export async function updateToDo ({idToken, id, title, details, isCompleted}) {
  const endpoint = API_ENDPOINTS.toDos
    .replace(/:TODO_ID/, id)
    .replace(/\/$/, '')

    isCompleted = isCompleted === 1;

  try {
    var response = await authorisedRequest(idToken, { method: 'PUT', endpoint, body: {title, details, isCompleted} })

    return response.updated;
  }
  catch(error) {
    throw new Error(error.message || 'There was a problem processing the request')
  }
}

export function toggleToDoCompletion ({idToken, toDoId, isCompleted }) {
  const endpoint = API_ENDPOINTS.toDoCompletion.replace(/:TODO_ID/, toDoId)

  return authorisedRequest(idToken, { method: 'PUT', endpoint, body: { isCompleted } })
    .then(response => {
      const { updated } = response
      return updated
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}