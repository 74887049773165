import React from 'react';

const NoPage = () => {

  const title = "Subheading";

  return (
      <div>
        <h2 className='h2' style={{ fontSize: "30px", margin: "0px" }}>404 - Page Not Found {title}</h2>
        <p>
          Sorry the page you want cannot be found.
        </p>
      </div>
    );
  };
  
  export default NoPage;