const { REACT_APP_API_HOST: apiHost } = process.env

export const API_ENDPOINTS = {
  /* Auth */
  login: `/api/login`,
  changePassword: `/api/changePassword`,
  refreshIdToken: `/api/refreshIdToken`,

  /* Customers */
  customers: `/api/customers/:CUSTOMER_ID`,

  /* Users */
  users: `/api/users/:USER_ID`,
  userStatus: `/api/users/:USER_ID/status`,

  /* WikiPages */
  wikiPages: `/api/wikiPages/:WIKI_PAGE_ID`,

  /* ToDos */
  toDos: `/api/todos/:TODO_ID`,
  toDoCompletion: `/api/todos/:TODO_ID/status`,

  /* ToDoCategory */
  toDoCategory: `/api/todocategory/:ID`,
}
