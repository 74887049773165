import { authorisedRequest } from '../lib/httpRequest'
import { API_ENDPOINTS } from '../lib/api-endpoints'

/*
 * ToDo API
 */

export function addToDoCategory ({idToken, toDoID, label} ) {
  const endpoint = API_ENDPOINTS.toDoCategory.replace(/:ID/, '').replace(/\/$/, '')

  return authorisedRequest(idToken, {
      method: 'POST',
      endpoint,
      body: {
        toDoID,
        label
      }
    })
    .then(response => {
      const { todocategory_id: todocategory_id } = response
      return { id: parseInt(todocategory_id, 10) }
    })
    .catch(error => {
      // HTTP conflict, email address exists
      throw new Error(error.message || 'There was a problem processing the request')
    })
}

export function deleteToDoCategory ({ idToken, categoryId }) {
  const endpoint = API_ENDPOINTS.toDoCategory.replace(/:ID/, categoryId)

  return authorisedRequest(idToken, { method: 'DELETE', endpoint })
    .then(response => {
      const { deleted } = response
      return deleted
    })
    .catch(error => {
      throw new Error(error.message || 'There was a problem processing the request')
    })
}
