import axios from 'axios'
import { API_ENDPOINTS } from './api-endpoints'

function logRequestResponse (response, type) {
  if (process.env.NODE_ENV !== 'production') {
    console.group(
      `%c API CALL RESPONSE %creceived @ ${new Date().toLocaleString()}`,
      'color: #93014C',
      'font-weight: normal; color: #7F7E7E'
    )
    let color = '#F54725' // non-authorized
    if (type === 'error') color = 'red'
    if (type === 'authorized') color = '#CD6AEF'
    console.log(
      `%c${type} %crequest/response:`,
      `font-weight: bold; color: ${color}`,
      'font-weight: normal; color: #7F7E7E',
      response
    )
    console.groupEnd('API REQUEST RESPONSE')
  }
}

export class HttpError extends Error {
  constructor (message, requestConfig, response) {
    super(message)
    this.name = 'HttpError'
    this.config = requestConfig
    this.response = response
  }
}

axios.interceptors.response.use(
  response => response,
  error => {
    logRequestResponse(
      {
        request: error.config,
        response: error.response
      },
      'error'
    )
    if (error.response && error.response.status === 401) {
      throw new HttpError('401', error.response.config)
    }
    if (
      error.response &&
      error.response.config &&
      error.response.config.url &&
      error.response.status === 400
    ) {
      throw new HttpError('400', error.response.config)
    }
    if (
      error.response &&
      error.response.config &&
      error.response.config.url &&
      error.response.status === 409
    ) {
      throw new HttpError('409', error.response.config, error.response)
    }
    throw new Error(error)
  }
)

function request ({ method, endpoint, body = null, headers = {} }) {
  const config = {
    method,
    url: endpoint,
    data: body,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headers },
    timeout: 30000
  }

  return axios(config)
}

export async function authorisedRequest (authToken, params) {
  
  return request({
    ...params,
    headers: { Authorization: `Bearer ${authToken}` }
  }).then(response => {
    logRequestResponse(response, 'authorised')
    if (response.status === 401) {
      throw new Error('Unauthorised')
    }

    return response.data
  })
}

export function nonAuthorisedRequest (params) {
  return request({
    ...params
  }).then(response => {
    logRequestResponse(response, 'non-authorised')
    if (response.status === 401) {
      throw new Error('Unauthorised')
    }

    return response.data
  })
}

export function loginRequest ({ email, password }) {

  return request({
    method: 'post',
    endpoint: API_ENDPOINTS.login,
    body: { email, password }
  })
    .then(response => {
      logRequestResponse(response, 'non-authorized')
      if (response.status === 200) {

        return {
          auth: { idToken: response.data.idToken, refreshToken: response.data.refreshToken },
          user: { id: response.data.id, displayName: response.data.displayName, email: response.data.email, role: response.data.role }   
        }

      }
      else {
        throw new Error('There was an error processing the request')
      }
    })
    .catch(error => {
      const errorMessage =
        error.message === '401' ? 'Invalid username or password' : error.message
      throw new Error(errorMessage)
    })
}
