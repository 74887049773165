import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                Footer Component
                <p style={{ fontSize: "medium" }}>
                    Currently static content - can be moved to editable once we have editable content implemented.

                    This would include links to editable content pages and static pages.
                </p>
            </div>
        )
    }
}
