
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createToDoCategoryAsync, deleteToDoCategoryAsync } from './toDoCategorySlice';
import { getAuthToken } from '../authentication/authenticationSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import { addNotification, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

const wait = (seconds) => 
    new Promise(
        resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
);

export function AddCategoryPopup ({    
    isOpen, onClose, toDoID
}) {

    const dispatch = useDispatch();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        // setValues(initialValues)
        setValues(initialValues)
        onClose()
    }

    const idToken = useSelector(getAuthToken);

    const handleSubmit = () => {
        console.log(">> Add category to To Do. ToDoID: " + toDoID);
        console.log(">> Add category to To Do. Category: " + values.category);


        dispatch( createToDoCategoryAsync({idToken, toDoID, label: values.category} ) ).unwrap().then((result) => {
            // handle result here
            let timestamp = Date.now();
            let msg = new uiMsg("New to do category created.", "INFO", timestamp);
            dispatch( addNotification(JSON.stringify(msg) ) );
    
            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })

            onClose();
        })
        .catch((error) => {  
            // handle error here
            let timestamp = Date.now();
            let msg = new uiMsg(error.message === "401" ? "Unauthorised to do categories." : error.message, "WARNING", timestamp);
            dispatch( addNotification(JSON.stringify(msg) ) );
    
            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })
        });

        setValues(initialValues)
        onClose();
    } 

    const initialValues = {
        category: ''
    };

    const handleChange = field => event => {
        const { value } = event.target
        setValues(vals => ({ ...vals, [field]: value }))
    }

    const [values, setValues] = React.useState(initialValues);

    return (

        <Modal
          open={isOpen}
          onClose={onClose}
          title="Add Category"
        >
            <Box sx={style} component="form" >

                <Stack direction="row" spacing={1}>
                    <TextField
                        label="Add Category"
                        id="outlined-size-small"
                        size="small"
                        value={values.category}
                        onChange={handleChange('category')}
                    />

                    <AddIcon  onClick={ handleSubmit }/>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <ClearIcon  onClick={ handleClose }/>
                </Stack>
            </Box>
        </Modal>

    );
}