import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changePasswordAsync, selectAllUsers } from './userSlice';
import { getAuthToken } from '../authentication/authenticationSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import { addNotification, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

const wait = (seconds) => 
    new Promise(
        resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
);

export function ChangeOtherPasswordPopup ({
    isOpen,
    onClose,
    userId
  }) {

    const dispatch = useDispatch();

    const initialValues = {
        userId: null,
        name: '',
        password: '',
        isSubmitted: false
    };

    const [values, setValues] = React.useState(initialValues)
    const users = useSelector(selectAllUsers);
    const user = users.find(u => u.id === userId)

    const handleChange = field => event => {
      const { value } = event.target
      setValues(vals => ({ ...vals, [field]: value }))
    }
    
    const handleClose = () => {
        setValues(initialValues)
        onClose()
    }
          
    const idToken = useSelector(getAuthToken);

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setValues(vals => ({ ...vals, isSubmitted: true }))
        const { password } = values

        if(user !== undefined ) {

          dispatch( changePasswordAsync({idToken, userId, password} ) ).unwrap().then((result) => {
              // handle result here
              let timestamp = Date.now();
              let msg = new uiMsg("Change other's password successful.", "INFO", timestamp);
              dispatch( addNotification(JSON.stringify(msg) ) );
      
              wait(3).then(function() {
                  dispatch( dismissNotification(timestamp) );
              })

              onClose();
          })
          .catch((error) => {  
              // handle error here
              let timestamp = Date.now();
              let msg = new uiMsg(error.message === "401" ? "Unauthorised to change the password of another user." : error.message, "WARNING", timestamp);
              dispatch( addNotification(JSON.stringify(msg) ) );
      
              wait(3).then(function() {
                  dispatch( dismissNotification(timestamp) );
              })
          });
      }
    };

    const defaultTextFieldProps = {
        fullWidth: true,
        variant: 'outlined'
      }
    
      const fields = [
        {
          ...defaultTextFieldProps,
          id: 'password',
          label: 'Password',
          type: 'password',
          required: true,
          value: values.password,
          onChange: handleChange('password'),
          error: values.isSubmitted && String(values.password).length === 0
        }
      ]

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      return (
        <Modal
          open={isOpen}
          onClose={onClose}
          title="Change Password"
        >
            <Box sx={style} component="form" >

                <h2 style={{marginBottom: "10px"}}>Change password {user !== undefined ? "for " + user.name : " - Initialising"}</h2>

                <Grid container spacing={2}>
                    {fields.map(({ id, ...props }) => (
                    <Grid key={id} item xs={12}>
                        <TextField id={id} {...props} />
                    </Grid>
                    ))}

                    <Box sx={{ flexGrow: 1 }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                        <Grid container spacing={2}>
                            <Grid size={6}>
                                <Button variant="outlined" onClick={ handleSubmit }>Save</Button>
                            </Grid>
                            <Grid size={6}>
                                <Button variant="outlined" onClick={ handleClose }>Close</Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
            </Box>

        </Modal>
      )
    }