import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createToDoAsync, selectAllToDos, addToDo, updateToDoAsync, updateToDo } from './toDoSlice';
import { getAuthToken } from '../authentication/authenticationSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import { addNotification, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

const wait = (seconds) => 
    new Promise(
        resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
);

export function ToDoPopup ({
    isOpen,
    onClose,
    selectedID
  }) {

    const dispatch = useDispatch();

    const initialValues = {
        id: null,
        title: '',
        details: '',
        isCompleted: false
    };

    const [values, setValues] = React.useState(initialValues)
    const toDos = useSelector(selectAllToDos);
    const toDo = toDos.find(td => td.id === selectedID)

    if (toDo && toDo.id !== values.id) {
        setValues({
            id:             toDo.id,
            title:          toDo.title,
            details:        toDo.details,
            isCompleted:    toDo.isCompleted
        })
    }
    else if (!toDo && values.id !== null) {
        setValues(initialValues)
    }


    const handleChange = field => event => {
      const { value } = event.target
      setValues(vals => ({ ...vals, [field]: value }))
    }

    
    const handleClose = () => {
        setValues(initialValues)
        onClose()
    }
    
      
    const idToken = useSelector(getAuthToken);

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setValues(vals => ({ ...vals, isSubmitted: true }))
        const { id, title, details, isCompleted } = values
        if (String(title).length === 0) return

        // New To Do
        if(toDo === undefined ) {
          dispatch( createToDoAsync({idToken, title, details, isCompleted} ) ).unwrap().then((result) => {
              // handle result here
              dispatch( addToDo({ id: result.id, title, details, isCompleted: 0, categories: [] } ) );

              setValues(initialValues);

              let timestamp = Date.now();
              let msg = new uiMsg("New to do created.", "INFO", timestamp);
              dispatch( addNotification(JSON.stringify(msg) ) );
      
              wait(3).then(function() {
                  dispatch( dismissNotification(timestamp) );
              })

              onClose();
          })
          .catch((error) => {  
              // handle error here
              let timestamp = Date.now();
              let msg = new uiMsg(error.message === "401" ? "Unauthorised to add to dos." : error.message, "WARNING", timestamp);
              dispatch( addNotification(JSON.stringify(msg) ) );
      
              wait(3).then(function() {
                  dispatch( dismissNotification(timestamp) );
              })
          });
      }

      // Update to do
      else {
        dispatch( updateToDoAsync({idToken, id, title, details, isCompleted} ) ).unwrap().then((result) => {

            // handle result here
            dispatch( updateToDo({ id, title, details, isCompleted } ) );

            let timestamp = Date.now();
            let msg = new uiMsg("To do updated.", "INFO", timestamp);
            dispatch( addNotification(JSON.stringify(msg) ) );
    
            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })

            onClose();
        })
        .catch((error) => {  
            // handle error here
            let timestamp = Date.now();
            let msg = new uiMsg(error.message === "401" ? "Unauthorised to update to dos." : error.message, "WARNING", timestamp);
            dispatch( addNotification(JSON.stringify(msg) ) );
    
            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })
        });

      }
    };


    const defaultTextFieldProps = {
        fullWidth: true,
        variant: 'outlined'
      }
    
      const fields = [
        {
          ...defaultTextFieldProps,
          autoFocus: true,
          id: 'title',
          label: 'Title',
          type: 'text',
          required: true,
          value: values.title,
          onChange: handleChange('title'),
          error: values.isSubmitted && String(values.title).length === 0
        },
        {
          ...defaultTextFieldProps,
          id: 'details',
          label: 'Description of the to do.',
          type: 'text',
          required: true,
          value: values.details,
          onChange: handleChange('details')
        }
      ]


      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };


      return (
        <Modal
          open={isOpen}
          onClose={onClose}
          title={toDo !== undefined ? "Update To Do" : "New To Do"}
        >
            <Box sx={style} component="form" onSubmit={handleSubmit}>

                <h2 style={{marginBottom: "0px"}}>{toDo !== undefined ? "Update To Do" : "Create New To Do"}</h2>
                <DialogContentText style={{marginBottom: "15px"}}>
                    Fill out the fields to add a new to do.
                </DialogContentText>

                <Grid container spacing={2}>
                    {fields.map(({ id, ...props }) => (
                      <Grid key={id} item xs={12}>
                          <TextField id={id} {...props} />
                      </Grid>
                    ))}

                    <Box sx={{ flexGrow: 1 }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                        <Grid container spacing={2}>
                            <Grid size={6}>
                                <Button variant="outlined" onClick={ handleSubmit }>{toDo !== undefined ? "Update" : "Create"}</Button>
                            </Grid>
                            <Grid size={6}>
                                <Button variant="outlined" onClick={ handleClose }>Close</Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>
            </Box>

        </Modal>
      )
    }