import React, { useState } from 'react';
import styles from './LoginPopup.css';
import { useDispatch } from 'react-redux';

import { loginAsync } from './authenticationSlice';
import { addNotification, clearNotifications, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

const wait = (seconds) => 
    new Promise(
        resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
);

export function LoginPopup (props) {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("NEW");
    const [errorMsg, setErrorMsg] = useState("");

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        let timestamp = Date.now();

        dispatch( loginAsync({ email, password }) ).unwrap().then((result) => {
            // handle result here
            dispatch( clearNotifications() );

            let msg = new uiMsg("Login successful", "INFO", timestamp);
            dispatch( addNotification(JSON.stringify(msg)) );

            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })

            setStatus("SUBMITTED");
        })
        .catch((error) => {  
            // handle error here
            var isNetworkError =  error.message.indexOf("Network Error") !== -1;
            var message = isNetworkError !== true ? "Login unsuccessful. <br/>Please check your username and password." : "Service not running or network error. <br/>Please contact your system administrator.";
            setErrorMsg(message);

            let msg = new uiMsg(message, isNetworkError ? "ERROR" : "WARNING", timestamp);
            dispatch( addNotification(JSON.stringify(msg)) );

            wait(3).then(function() {
                dispatch( dismissNotification(timestamp) );
            })

            setStatus("ERROR");
        });

    };

    const labelStyle = {
        display: "inline-block",
        minWidth: "70px",
        paddingRight: "5px",
        textAlign: "right"
    };


    const formInputStyle = {
        marginLeft: "auto",
        marginRight: "auto",
        width: "300px"
    };


    const buttonsContainerStyle = {
        marginLeft: "auto",
        marginRight: "auto",
        width: "110px"
    };

    return (
        <div className={styles.popup}>
            <div className={styles.popup_inner}>
                <h2>Login</h2>

                <form onSubmit={handleSubmit}>
                    <p style={formInputStyle}>
                        <label style={labelStyle}>
                            Email:
                        </label>
                        <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
                    </p>
                    <p style={formInputStyle}>
                        <label style={labelStyle}>
                            Password:
                        </label>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} autoComplete="false" />
                    </p>
                    <div style={buttonsContainerStyle}>
                        <button type="submit">Login</button>&nbsp;
                        <button type="button" onClick={props.closeDialog}>Close</button>
                    </div>
                </form>
                {
                    status === "ERROR" ?
                        <div style={{border: "1px solid lightgrey", width: "100%", textAlign: "center", padding: "5px", fontStyle: "italic", color: "grey", marginTop: "15px"}}>
                            <p dangerouslySetInnerHTML={{ __html: errorMsg }}></p>
                        </div>
                        : ""
                }
            
            </div>
        </div>
    );
}