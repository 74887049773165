import { configureStore, thunk } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authenticationReducer from '../features/authentication/authenticationSlice';
import notificationReducer from '../features/notification/notificationSlice';
import userReducer from '../features/users/userSlice';
import toDoReducer from '../features/todos/toDoSlice';

import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  counter: counterReducer,
  authentication: authenticationReducer,
  notification: notificationReducer,
  todo: toDoReducer,
  user: userReducer,
  middleware: [thunk]
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});