import React from 'react';

import { CurrentNotifications } from '../notification/CurrentNotifications';
import { DismissedNotifications } from '../notification/DismissedNotifications';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ToDoPage from "../todos/ToDoPage";
import NoPage from "../../pages/NoPage";
import UserPage from "../users/UserPage"

export function Main() {

  function Home() {

    return (
      <>
        <h2 className='h2' style={{ fontSize: "30px" }}>Home</h2>
        <p>
          Placeholder for a home/landing page.
        </p>
        <p>
          Currently static content - can be moved to editable page once we have editable content (pages) implemented.
        </p>
        <p>
          The repo for this project can be found at: <a href="https://git.apcs.com.au/greg/php_react_template/-/tree/master" target="_blank">https://git.apcs.com.au/greg/php_react_template/-/tree/master</a>.
        </p>
      </>
    );
  }

  function Contact() {
    return (
      <>
        <h2 className='h2' style={{ fontSize: "30px" }}>Contact</h2>
        <p>
          Placeholder for Contact Us details.
        </p>
        <p>
          Currently static content - can be moved to editable page once we have editable content (pages) implemented.
        </p>
      </>
    );
  }


  return (
    <div>
      <div className="dashboard" style={{ minHeight: "calc(100vh - 225px)" }}>
          <div style={{ zIndex: "1000", width: "auto", position: "fixed", top: "70px", right: "0px" }}>
            <div>
              <CurrentNotifications />
            </div>
            <div>
              <DismissedNotifications />
            </div>
          </div>

          <Routes>
            <Route strict path="/" element={<Home />} />
    
            <Route path="/contact" element={<Contact />} />
            
            <Route path="/todo" element={<ToDoPage />} />
            
            <Route path="/users" element={<UserPage />} />

            <Route path="*" element={<NoPage />} />
          </Routes>
      </div>
    </div>
  );
}
