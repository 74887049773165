export class uiMsg {

  constructor( message, type, timestamp = Date.now() ) {

    let dismissDelay = 10000;
    if (type === "ERROR") {
      dismissDelay = 300000;
    }

    this.message = message;
    this.type = type;
    this.dateTimeOf = timestamp;
    this.status = "SHOW";
  }
};
