import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: []
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addNotification: (state, action) => {

      return {
        ...state,
        notifications: [...state.notifications, JSON.parse(action.payload) ]
      }
    },
    dismissNotification: (state, action) => {

      return {
          ...state,
          notifications: state.notifications.map( n =>
            n.dateTimeOf === action.payload
            ? { ...n, status: "DISMISSED" }
            : { ...n }
        )
      }
    },
    clearNotifications: () => {
      return initialState
    }
  },
});

export const { addNotification, clearNotifications, dismissExpiredNotifications, dismissNotification } = notificationSlice.actions;

export const selectAllNotifications = (state) => state.notification.notifications;
export const selectCurrentNotifications = (state) => state.notification.notifications.filter(msg => msg.status === "SHOW");
export const selectDismissedNotifications = (state) => state.notification.notifications.filter(msg => msg.status === "DISMISSED");

export default notificationSlice.reducer;
