import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import { Authenticate } from './features/authentication/Authenticate';

import './Header.css';

export default function Header() {

    const styleMenuItem = {
        display: "inline-block",
        fontSize: "20px",
        listStyleType: "none",
        marginRight: "10px"
    }

    const location = useLocation();
    // const loggedOnEmail = useSelector(state => state.authentication.user.email);

    const loggedInRole = useSelector(state => state.authentication.user.role);  //State > authentication >  user > role

    return (
        <div className="header" style={{ position: "relative", top: "0", right: "0px", width: "100%"}}>
            Header Component

            <nav>
                <ul style={{ paddingLeft: "0px", margin: "0px" }}>
                    <li style={styleMenuItem}>
                        <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
                    </li>
                    <li style={styleMenuItem}>
                        <Link to="/todo" className={`nav-link ${location.pathname === '/todo' ? 'active' : ''}`}>To Dos</Link>
                    </li>
                    <li style={styleMenuItem}>
                        <Link to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Link>
                    </li>
                    {
                        loggedInRole === "admin"
                            ?
                        <li style={styleMenuItem}>
                            <Link to="/users" className={`nav-link ${location.pathname === '/users' ? 'active' : ''}`}>Users</Link>
                        </li>
                            :
                        ""
                    }
                </ul>
            </nav>

            <Authenticate />
        </div>
    )
}
