import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  logout } from './authenticationSlice';

import { LoginPopup } from './LoginPopup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { clearNotifications } from '../notification/notificationSlice';

const styleLogin = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function Authenticate() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const loggedOnEmail = useSelector(state => state.authentication.user.email);
  const displayName = useSelector(state => state.authentication.user.displayName);

  const doLogout = () => {
    dispatch(logout());
    dispatch(clearNotifications());
  }
  
  return (
    <div style={{ position: "absolute", top: "0", right: "10px"}}>
      <div style={{ fontSize: "medium", color: "darkslategray", fontWeight: "bolder", marginTop: "10px" }}>
        { displayName !== undefined ? displayName : ""}
      </div>
      <div style={{textAlign: "right"}}>
        {
          loggedOnEmail !== undefined
        ?
          <button
            aria-label="Logout"
            onClick={() => dispatch(doLogout())}
          >
            Log Out
          </button>
        :
          <div>
            <Button onClick={handleOpen}>Login</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleLogin}>
                <LoginPopup closeDialog={handleClose} />
              </Box>
            </Modal>
          </div>
        }
      </div>
    </div>
  );
}
