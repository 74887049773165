import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";


import { addNotification, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

import { getAuthToken } from '../authentication/authenticationSlice';


import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Fab from '@mui/material/Fab';


import { ToDoPopup } from './ToDoPopup';
import { AddCategoryPopup } from './AddCategoryPopup';


import { getToDosAsync, setToDos, selectAllToDos, toggleToDoCompletionAsync, toggleToDoCompletion, deleteToDoAsync, deleteToDo, removeCategoryFromToDo } from './toDoSlice';
import { deleteToDoCategoryAsync } from './toDoCategorySlice';
import { TextField } from '@mui/material';

export function ToDoPage (props) {

  const toDos = useSelector(selectAllToDos);

  const [isOpenEditToDo, setIsOpenEditToDo] = React.useState(false);
  const handleOpenEditToDo = () => setIsOpenEditToDo(true);
  const handleCloseEditToDo = () => setIsOpenEditToDo(false);

  const [selectedId, setSelectedId] = useState(-1);
  const [search, setSearch] = React.useState("");

  const [showCompleted, setShowCompleted] = React.useState(0);

  const loggedOnEmail = useSelector(state => state.authentication.user.email);

  const updateSearch = (value) => {
    setSearch(value);
  }

  const searchToDos = (toDos, showCompleted) => {
    if(loggedOnEmail === undefined) {
      return [];
    }

    if(search === "") {
      return toDos.filter( todo => todo.isCompleted === showCompleted )
    }

    return toDos.filter( todo => 
      (
        JSON.stringify(todo).toLowerCase().includes(search.toLowerCase()) 
      ) && 
      todo.isCompleted === showCompleted
    )
  };

  const navigate = useNavigate();

  const idToken = useSelector(getAuthToken);

  const wait = (seconds) => 
    new Promise(resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
    );
  
  const dispatch = useDispatch();

  dispatch( getToDosAsync(idToken) ).unwrap().then((result) => {
      // handle result here
      if (result.length !== 0 && JSON.stringify(toDos) !== JSON.stringify(result)) {
        dispatch( setToDos(result) );
      }
    }
  )
  .catch((error) => {  
        // handle error here
        let timestamp = Date.now();
        let msg = new uiMsg(error.message === "401" ? "Unauthorised to view to dos." : error.message, "WARNING", timestamp);
        dispatch( addNotification(JSON.stringify(msg) ) );
        
        wait(3).then(function() {
            dispatch( dismissNotification(timestamp) );
            navigate("/");
        })
    }
  );

  const handleNewToDo = () => {
    setSelectedId(-1);
    handleOpenEditToDo();
  }

  const handleEditToDo = (toDoID) => {
    setSelectedId(toDoID);
    handleOpenEditToDo();
  }

  const handleDeleteToDo = (toDoId) => {

    dispatch(deleteToDoAsync ({idToken, toDoId})).unwrap().then((result) => {
        // handle result here
        if (result === true) {
          dispatch( deleteToDo({toDoId}) );
        }
      }
    )
    .catch((error) => {  
          // handle error here
          let timestamp = Date.now();
          let msg = new uiMsg(error.message === "401" ? "Unauthorised to delete to dos." : error.message, "WARNING", timestamp);
          dispatch( addNotification(JSON.stringify(msg) ) );

          wait(3).then(function() {
              dispatch( dismissNotification(timestamp) );
          })
      }
    );

  }

  const handleToggleCompletion = (toDoId, isCompleted) => {

    dispatch(toggleToDoCompletionAsync ({idToken, toDoId, isCompleted})).unwrap().then((result) => {
        // handle result here
        if (result === true) {
          dispatch( toggleToDoCompletion({toDoId, isCompleted}) );
        }
      }
    )
    .catch((error) => {  
          // handle error here
          let timestamp = Date.now();
          let msg = new uiMsg(error.message === "401" ? "Unauthorised to edit to dos." : error.message, "WARNING", timestamp);
          dispatch( addNotification(JSON.stringify(msg) ) );

          wait(3).then(function() {
              dispatch( dismissNotification(timestamp) );
          })
      }
    );
  }

  const handleDeleteCategory = (toDoID, categoryId) => {
    
    dispatch( deleteToDoCategoryAsync({idToken, categoryId} ) ).unwrap().then((result) => {

        dispatch(removeCategoryFromToDo(toDoID, categoryId));

        // handle result here
        let timestamp = Date.now();
        let msg = new uiMsg("To do category deleted.", "INFO", timestamp);
        dispatch( addNotification(JSON.stringify(msg) ) );

        wait(3).then(function() {
            dispatch( dismissNotification(timestamp) );
        })

        onClose();
    })
    .catch((error) => {  
        // handle error here
        let timestamp = Date.now();
        let msg = new uiMsg(error.message === "401" ? "Unauthorised to do categories." : error.message, "WARNING", timestamp);
        dispatch( addNotification(JSON.stringify(msg) ) );

        wait(3).then(function() {
            dispatch( dismissNotification(timestamp) );
        })
    });
  }

  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
  const handleShowAddCategoryPopup = (toDoID) => {
    setSelectedId(toDoID)
    setShowAddCategoryPopup(true)
  };
  const handleHideAddCategoryPopup = () => {
    console.log("Hide Category Popup.");
    setSelectedId(-1);
    setShowAddCategoryPopup(false)
  };  

  const tableStyles = {
    table: {
      '& tbody>.MuiTableRow-root:hover': {
        background: 'antiquewhite',
        cursor: "pointer",
      }
    },
  };

  return (
      <div>
        <h2 className='h2' style={{ fontSize: "30px" }}>To Dos</h2>

        <TableContainer component={Paper} sx={tableStyles.table}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left" colSpan={2}>
                  <TextField id="search_todos" label="Search" variant="outlined" size="small" onChange={(e) => {
                    updateSearch(e.target.value);
                  }}/>
                  {/* <SearchIcon style={{ marginTop: "5px", marginLeft: "5px"}} onClick={(e) => alert("hello. --" + search + "--") }/> */}
                </TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">
                  <FormControlLabel control={
                    <Switch 
                      color='primary'
                      checked={showCompleted === 1}
                      onChange={(e) => setShowCompleted( showCompleted === 1 ? 0 : 1 )}
                    />
                  } 
                  label="Completed" />
                </TableCell>
                <TableCell align="left">
                  <Button variant="outlined" onClick={ handleNewToDo }>New</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            
            <TableHead style={{background: "aliceblue"}}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Details</TableCell>
                <TableCell align="left">Categories</TableCell>
                <TableCell align="left">Is Completed</TableCell>
                <TableCell align="left">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchToDos(toDos, showCompleted).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell onClick={(e) => handleEditToDo(row.id, e)} component="th" scope="row">
                    {row.id}
                  </TableCell>

                  <TableCell align="left" onClick={(e) => handleEditToDo(row.id, e)} dangerouslySetInnerHTML={{__html: row.title }}></TableCell>

                  <TableCell align="left" onClick={(e) => handleEditToDo(row.id, e)} dangerouslySetInnerHTML={{__html: row.details }}></TableCell>

                  <TableCell component="th" scope="row">
                    <Stack direction="row" spacing={1}>
                      { 
                        row.categories.map((category) => (
                            <Chip key={row.id + "_" + category.id}  label={category.label} variant="outlined" onDelete={(e) => handleDeleteCategory(row.id, category.id, e)} />
                        ))
                      }

                      <Fab key={row.id + "_addCat"} size="small" color="primary" aria-label="add" style={{ width: "30px", height: "30px", minHeight: "30px"}}>
                          <AddIcon  onClick={(e) => handleShowAddCategoryPopup(row.id) }/>
                      </Fab>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">
                    <Switch
                      color='primary'
                      checked={row.isCompleted === 1}
                      onChange={(e) => handleToggleCompletion(row.id, row.isCompleted !== 1, e)}
                    />
                  </TableCell>
                  <TableCell onClick={(e) => handleDeleteToDo(row.id) }>
                    <IconButton aria-label='delete' >
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <AddCategoryPopup isOpen={showAddCategoryPopup} onClose={handleHideAddCategoryPopup} toDoID={selectedId} />
        <ToDoPopup isOpen={isOpenEditToDo} onClose={handleCloseEditToDo} selectedID={selectedId} />

      </div>
    );
  };
  
  export default ToDoPage;