import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { loginRequest } from '../lib/httpRequest';

const initialState = {
  auth: {},
  user: {},
  status: "idle",
  loginVisibility: "hidden",
  error: ""
};

  // The function below is called a thunk and allows us to perform async logic. It
  // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
  // will call the thunk with the `dispatch` function as the first argument. Async
  // code can then be executed and other actions can be dispatched. Thunks are
  // typically used to make async requests.
  export const loginAsync = createAsyncThunk(
    'authenticate/login',
    async (credentials) => {

      const response = loginRequest(credentials);

      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  );


export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // that has all the existing state data
      return initialState
    },
  },


  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { auth, user } = action.payload

        state.status = 'idle';
        state.auth = auth;
        state.user = user;

        state.loginVisibility = "hidden";
      });
  },
});

export const { logout } = authenticationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEmail = (state) => state.user.email;
export const selectRole = (state) => state.user.role;
export const selectLoginVisibility = (state) => state.authentication.loginVisibility;

export const getAuthToken = (state) => state ? state.authentication.auth.idToken : null;


/*
  // We can also write thunks by hand, which may contain both sync and async logic.
  // Here's an example of conditionally dispatching actions based on current state.
  export const incrementIfOdd = (amount) => (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  };
*/

export default authenticationSlice.reducer;
