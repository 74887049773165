import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getUsersAsync, setUsers, selectAllUsers, toggleUserStatusAsync, toggleUserStatus, deleteUserAsync, deleteUser, changePasswordAsync} from './userSlice';

import { addNotification, dismissNotification  } from '../notification/notificationSlice';
import { uiMsg } from '../notification/uiMsg';

import { getAuthToken } from '../authentication/authenticationSlice';



import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { UserPopup } from './UserPopup';
import { ChangeOtherPasswordPopup } from './ChangeOtherPasswordPopup';


export function UserPage (props) {

  const users = useSelector(selectAllUsers);
  const loggedInUserId = useSelector(state => state.authentication.user.id);

  const [isOpenEditUser, setIsOpenEditUser] = React.useState(false);
  const handleOpenEditUser = () => setIsOpenEditUser(true);
  const handleCloseEditUser = () => setIsOpenEditUser(false);



  const [isOpenChangePassword, setIsOpenChangePassword] = React.useState(false);
  const handleOpenChangePassword = () => setIsOpenChangePassword(true);
  const handleCloseChangePassword = () => setIsOpenChangePassword(false);

  const [selectedId, setSelectedId] = useState(-1);

  const navigate = useNavigate();

  const idToken = useSelector(getAuthToken);

  const wait = (seconds) => 
    new Promise(resolve => 
       setTimeout(() => resolve(true), seconds * 1000)
    );

  const dispatch = useDispatch();

  dispatch( getUsersAsync(idToken) ).unwrap().then((result) => {
      // handle result here
      if (result.length !== 0 && users.length !== result.length) {
        dispatch( setUsers(result) );
      }
    }
  )
  .catch((error) => {  
        // handle error here
        let timestamp = Date.now();
        let msg = new uiMsg(error.message === "401" ? "Unauthorised to view users." : error.message, "WARNING", timestamp);
        dispatch( addNotification(JSON.stringify(msg) ) );
        
        navigate("/");

        wait(3).then(function() {
            dispatch( dismissNotification(timestamp) );
        })
    }
  );


  const handleNewUser = () => {
    setSelectedId(-1);
    handleOpenEditUser();
  }


  const handleEditUser = (userID) => {
    setSelectedId(userID);
    handleOpenEditUser();
  }

  const handleToggle = (userId, isActive) => {

    dispatch(toggleUserStatusAsync ({idToken, userId, isActive})).unwrap().then((result) => {
        // handle result here
        if (result === true) {
          dispatch( toggleUserStatus({userId, isActive}) );
        }
      }
    )
    .catch((error) => {  
          // handle error here
          let timestamp = Date.now();
          let msg = new uiMsg(error.message === "401" ? "Unauthorised to edit users." : error.message, "WARNING", timestamp);
          dispatch( addNotification(JSON.stringify(msg) ) );

          wait(3).then(function() {
              dispatch( dismissNotification(timestamp) );
          })
      }
    );
  }

  const handleDeleteUser = (userId) => {

    dispatch(deleteUserAsync ({idToken, userId})).unwrap().then((result) => {
        // handle result here
        if (result === true) {
          dispatch( deleteUser({userId}) );
        }
      }
    )
    .catch((error) => {  
          // handle error here
          let timestamp = Date.now();
          let msg = new uiMsg(error.message === "401" ? "Unauthorised to delete users." : error.message, "WARNING", timestamp);
          dispatch( addNotification(JSON.stringify(msg) ) );

          wait(3).then(function() {
              dispatch( dismissNotification(timestamp) );
          })
      }
    );

  }


  const handleChangePassword = (userId) => {
    setSelectedId(userId);
    handleOpenChangePassword();
  }

  const onMouseEnterStyle = (e) => {
    e.target.style.backgroundColor = 'antiquewhite';
    e.target.style.border = '2px solid rgb(31, 0, 69)';
    e.target.style.boxShadow = '-2px 0px 7px 2px #e13570';
  };

  const onMouseLeaveStyle = (e) => {
      e.target.style.backgroundColor = 'white';
      e.target.style.border = 'none';
      e.target.style.borderBottom = '1px solid rgb(224, 224, 224)';
      e.target.style.boxShadow = 'none';
  };

  const tableStyles = {
    table: {
      '& tbody>.MuiTableRow-root:hover': {
        background: 'antiquewhite',
        cursor: "pointer",
      }
    },
  };

  return (
      <div>
        <div style={{display: "flex", marginBottom: "5px", marginTop: "5px"}}>
          <div style={{flex: "50%"}}>
            <h2 className='h2' style={{ fontSize: "30px", margin: "0px" }}>Users</h2>
          </div>
          <div style={{flex: "50%", textAlign: "right"}}>
            <Button variant="outlined" onClick={ handleNewUser }>New</Button>

          </div>
        </div>
        
        <UserPopup isOpen={isOpenEditUser} onClose={handleCloseEditUser} selectedID={selectedId} />
        <ChangeOtherPasswordPopup isOpen={isOpenChangePassword} onClose={handleCloseChangePassword} userId={selectedId}  />


        <TableContainer component={Paper} sx={tableStyles.table}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{background: "aliceblue"}}>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Role</TableCell>
                <TableCell align="left">Password</TableCell>
                <TableCell align="left">Is Active</TableCell>
                <TableCell align="left">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" onClick={(e) => handleEditUser(row.id, e)}>
                    {row.id}
                  </TableCell>
                  <TableCell align="left" onClick={(e) => handleEditUser(row.id, e)}>{row.name}</TableCell>
                  <TableCell align="left" onClick={(e) => handleEditUser(row.id, e)} style={{cursor: "pointer"}}>{row.email}</TableCell>
                  <TableCell align="left" onClick={(e) => handleEditUser(row.id, e)} style={{cursor: "pointer"}}>{row.role}</TableCell>

                  <TableCell align="left">
                    <Button variant="outlined" disabled={loggedInUserId === row.id} onClick={(e) => handleChangePassword(row.id)} >Change Password</Button>
                  </TableCell>

                  <TableCell align="left">
                    <Switch
                      color='primary'
                      disabled={loggedInUserId === row.id}
                      checked={row.isActive === 1}
                      onChange={(e) => handleToggle(row.id, row.isActive !== 1, e)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label='delete' disabled={loggedInUserId === row.id} onClick={(e) => handleDeleteUser(row.id)}>
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

    );
  };
  
  export default UserPage;