import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUsers, addUser as sendAddUser, toggleUserStatus as toggleUser, updateUser as sendUpdateUser, deleteUser as sendDeleteUser, changeOthersPassword } from './userAPI'

const initialState = {
  selectedUserId: null,
  list: []
}
export const getUsersAsync = createAsyncThunk(
    'user/getUsers',
    async (idToken) => {

        const response = await getUsers(idToken);
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const createUserAsync = createAsyncThunk(
    'user/createUser',
    async (newUserRq ) => {

        console.log(">>> createUserAsync: user: " + JSON.stringify(newUserRq) );

        const response = await sendAddUser(newUserRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const updateUserAsync = createAsyncThunk(
    'user/updateUser',
    async (updateUserRq ) => {

        console.log(">>> updateUserAsync: user: " + JSON.stringify(updateUserRq) );

        const response = await sendUpdateUser(updateUserRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const deleteUserAsync = createAsyncThunk(
    'user/deleteUser',
    async (deleteUserRq ) => {

        console.log(">>> deleteUserAsync: user: " + JSON.stringify(deleteUserRq) );

        const response = await sendDeleteUser(deleteUserRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const toggleUserStatusAsync = createAsyncThunk(
    'user/toggleUserStatus',
    async ( toggleUserRq ) => {

        const response = await toggleUser( toggleUserRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const changePasswordAsync = createAsyncThunk(
    'user/changePassword',
    async ( changePasswordRq ) => {

        const response = await changeOthersPassword( changePasswordRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);



export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addUser: (state, action) => {

      return {
        ...state,
        list: [...state.list, action.payload]
      }
    },
    deleteUser: (state, action) => {

      var userId = action.payload;

      return {
        ...state,
        list: [...state.list.filter(u => u.id !== userId)]
      }
    },
    setUsers: (state, action) => {

      return {
        ...state,
        list: action.payload
      }
    },
    updateUser: (state, action) => {

      var user = action.payload;

      return {
        ...state,
        list: state.list.map(u => {
          if (u.id === user.id) {
            return user
          }
          return { ...u }
        })
      }
    },
    toggleUserStatus: (state, action) => {
      const userId = action.payload.userId
      const isActive = action.payload.isActive === true ? 1 : 0

      return {
        ...state,
        list: state.list.map(u => {
          if (u.id === userId) {
            return { ...u,isActive }
          }
          return { ...u }
        })
      }
    },
    clearState: () => {
      return initialState
    }
  },
});

export const { addUser, clearState, deleteUser, setUsers, toggleUserStatus, updateUser } = userSlice.actions;

export const selectAllUsers = (state) => state.user.list;

export default userSlice.reducer;
