import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { getToDos, addToDo as sendAddToDo, updateToDo as sendUpdateToDo, deleteToDo as sendDeleteToDo, toggleToDoCompletion as toggleToDoCompletionRq } from './toDoAPI'

import { addToDoCategory, deleteToDoCategory } from './toDoCategoryAPI'

export const createToDoCategoryAsync = createAsyncThunk(
    'todocategory/createToDoCategory',
    async (newToDoCategoryRq ) => {

        const response = await addToDoCategory(newToDoCategoryRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const deleteToDoCategoryAsync = createAsyncThunk(
    'todocategory/deleteToDoCategory',
    async (deleteToDoCategoryRq ) => {

        const response = await deleteToDoCategory(deleteToDoCategoryRq );
  
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);


export const toDoCategorySlice = createSlice({
  name: 'toDoCategory',
  initialState: {},
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
});



export default toDoCategorySlice.reducer;
